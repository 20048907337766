import React, { useEffect, useState } from "react";
import { FormWrapper, LeftWrapper, RightWrapper, Wrapper } from "../styles2";
import { useLoginContext } from "../LoginContext";
import API from "src/js/API";
import OTPInput from "../Components/OTPInput";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Icon, Text } from "@shopify/polaris";
import { ChevronLeftMinor } from "@shopify/polaris-icons";
import { getErrorMessageString } from "src/js/components/Toast";

const OTPVerify = () => {
	const { tempToken, handleJwtLogin } = useLoginContext();
	const [error, setError] = useState("");
	const history = useHistory();

	useEffect(() => {
		if (!tempToken) {
			history.push("/authentication/login");
		}
	}, [history, tempToken]);

	const handleVerify = async (otp: string) => {
		setError("");

		try {
			const res = await API.post(
				`/api/otp/verify.json`,
				{ otp_code: otp },
				{
					headers: {
						Authorization: `Bearer ${tempToken}`,
					},
				}
			);

			handleJwtLogin({
				token: res.data.token,
				refreshToken: res.data.refresh_token,
				// rememberMeChecked: true,
			});
		} catch (error) {
			console.error("Error verifying OTP:", error);
			const errorMessage = getErrorMessageString(error);
			setError(`Ett fel uppstod vid verifiering av OTP. \n ${errorMessage}`);
		}
	};

	if (!tempToken) return null;

	return (
		<Wrapper>
			<LeftWrapper>
				<h1>Ange engångskod</h1>
			</LeftWrapper>

			<RightWrapper>
				<FormWrapper>
					<OTPInput onComplete={handleVerify} error={error} />

					<Link to="/admin/authentication/login">
						<LinkWrapper>
							<Icon source={ChevronLeftMinor} />
							<Text variant="bodySm" as="p">
								Tillbaka till inloggningen
							</Text>
						</LinkWrapper>
					</Link>
				</FormWrapper>
			</RightWrapper>
		</Wrapper>
	);
};
export default OTPVerify;

const LinkWrapper = styled.div`
	display: flex;
	gap: 0.35rem;

	.Polaris-Icon {
		margin: 0;
	}
`;

import { Checkbox, FormLayout, Layout } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import API from "src/js/API";
import Page from "src/js/components/page";
import Toast from "src/js/components/Toast";
import useQuery from "src/js/hooks/useQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

const MFASettings = ({ t }) => {
	const [form, setForm] = useState<any>({});

	const queryClient = useQueryClient();
	const storeAccount = useSelector((state: any) => state.account);

	const { data: accountMfaSettings, isFetching } = useQuery({
		queryKey: [`account_${storeAccount.id}_mfa-settings`],
		queryFn: async () => {
			try {
				const res = await API.get(`/api/account/mfa_settings.json`);
				return res.data.account_mfa_settings || {};
			} catch (error) {
				console.error("error:", error);
				Toast.error(error);
			}
		},
		initialData: {},
	});

	useEffect(() => {
		setForm(accountMfaSettings || {});
	}, [accountMfaSettings]);

	const handleChange = (field: string) => (value: any) => {
		setForm((c) => ({ ...c, [field]: value }));
	};

	const handleSave = async () => {
		try {
			const res = await API.put(`/api/account/mfa_settings.json`, form);

			Toast.success(t("mfa.responses.mfa.updated", "MFA inställningar uppdaterade"));
			setForm(res.data.account_mfa_settings);

			queryClient.setQueryData(["mfa-settings"], res.data.account_mfa_settings);

			return res;
		} catch (error) {
			Toast.error(error);
		}
	};

	return (
		<Page
			loading={isFetching}
			title={t("mfa.settings.title", "Konto MFA inställningar")}
			primaryAction={{
				content: t("common.save", "Spara"),
				onAction: handleSave,
			}}
		>
			<Layout sectioned>
				<FormLayout>
					<Checkbox
						disabled={isFetching}
						label={t("mfa.settings.require_mfa	true.label", "Kräv MFA vid inloggning för alla användare")}
						checked={form?.require_mfa}
						onChange={(checked) => handleChange("require_mfa")(checked)}
						helpText={t("mfa.settings.require_mfa.true.help_text", "Om aktiverad kommer alla användare att behöva logga in med MFA.")}
					/>
				</FormLayout>
			</Layout>
		</Page>
	);
};
export default withTranslation(["mfa", "common"])(MFASettings);

import { createContext, use, useCallback, useMemo, useState } from "react";
import { Container } from "./styles2";
import { AddCookie } from "../Utilities";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { decodeTokenUser } from "../API/utils";

const LoginContext = createContext({} as any);

export const useLoginContext = () => {
	return use(LoginContext);
};

export const LoginProvider = ({ children }) => {
	const [tempToken, setTempToken] = useState("");
	const dispatch = useDispatch();
	const history = useHistory();

	const handleJwtLogin = useCallback(
		({ token, refreshToken, rememberMeChecked }: { token: string; refreshToken: string; rememberMeChecked?: boolean }) => {
			const tokenData = decodeTokenUser(token);
			AddCookie("JWTTOKEN", token, { expires: new Date(tokenData.exp * 1000 - 60000).toUTCString() });
			AddCookie("rememberMeChecked", rememberMeChecked);

			if (rememberMeChecked) {
				localStorage.setItem("refresh_token", refreshToken);
			} else {
				sessionStorage.setItem("refresh_token", refreshToken);
			}

			setTimeout(() => {
				dispatch({ type: "SET_REFRESH_TOKEN", refresh_token: refreshToken });
				dispatch({ type: "SET_TOKEN", token });

				// navigate("/admin");
				const url = `/admin/?refresh_token=${refreshToken}`;
				if (history) {
					history.replace(url);
				} else {
					window.location.assign(url);
				}
			}, 1);
		},
		[dispatch, history]
	);

	const value = useMemo(() => {
		return { tempToken, setTempToken, handleJwtLogin };
	}, [tempToken, setTempToken, handleJwtLogin]);

	return (
		<LoginContext value={value}>
			<Container>{children}</Container>
		</LoginContext>
	);
};

import { FormLayout } from "@shopify/polaris";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Button from "src/js/components/Button";
import SelectSearchField from "src/js/components/SelectSearchField";
import Toast from "src/js/components/Toast";
import Modal from "src/js/components/modal";

const MergeTicketIntoAnotherTicketModal = ({ ticket }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [ticketMergeTo, setTicketMergeTo] = useState<any>();
	const { t } = useTranslation(["ticket", "common"]);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleSubmit = async () => {
		try {
			await API.post(`/api/tickets/${ticket.id}}/merge.json`, {
				ticket_id: ticketMergeTo?.id,
			});
			Toast.success(t("ticket.responses.merged", "Ärende sammanslaget") as string);

			handleClose();
		} catch (error) {
			Toast.error(error);
		}
	};

	return (
		<>
			<Button onClick={handleOpen}>{t("ticket.merge_ticket_into_another_ticket.button.label", "Slå ihop ärende") as string}</Button>
			<Modal
				open={isOpen}
				onClose={handleClose}
				title={t("ticket.merge_ticket_into_another_ticket.modal.title", "Slå ihop ärende") as string}
				primaryAction={{
					content: t("ticket.merge_ticket_into_another_ticket.modal.actions.primary.label", "Slå ihop ärende") as string,
					onAction: handleSubmit,
				}}
				secondaryActions={[
					{
						content: t("common.cancel", "Avbryt") as string,
						onAction: handleClose,
					},
				]}
			>
				<FormLayout>
					<SelectSearchField
						value={ticketMergeTo}
						label={t("ticket.merge_ticket_into_another_ticket.modal.fields.ticket.label", "Ärende att slå ihop med") as string}
						placeholder={t("ticket.merge_ticket_into_another_ticket.modal.fields.ticket.placeholder", "Sök efter ett ärende") as string}
						resourceName={{ singular: "Ärende", plural: "Ärenden" }}
						resource="tickets"
						resourceHandle="tickets"
						labelHandle="label"
						resourceURL="/api/tickets.json"
						onSelect={(value) => {
							setTicketMergeTo(value);
						}}
					/>

					<p>
						{
							t(
								"ticket.merge_ticket_into_another_ticket.modal.description",
								"Nuvarande ärende kommer att tas bort och enbart konversationer kommer att kopieras till det valda ärendet."
							) as string
						}
					</p>
				</FormLayout>
			</Modal>
		</>
	);
};
export default MergeTicketIntoAnotherTicketModal;

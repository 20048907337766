/* eslint-disable quotes */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from "react";
import { PageActions, Banner, Layout, LegacyCard, Tag, FormLayout, Checkbox, Text } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import uniq from "lodash/uniq";
import { fetchAndSetTokens } from "src/js/API/API";
import API from "../../API";
import { store } from "../../store";

// import SkeletonProvider from "../../components/skeleton_provider.js";
import FieldSelector from "../../components/FieldSelector.js";
import UserRoles from "./UserRoles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useQuery from "src/js/hooks/useQuery";
import TextField from "src/js/components/TextField";
import Page from "src/js/components/page";
import useWorkspaces from "src/js/hooks/useWorkspaces";
import Toast from "src/js/components/Toast";
import { checkUserRoles } from "src/js/Utilities";
import Switch from "src/js/components/Switch";
import Modal from "src/js/components/modal";

const UserEdit = ({ history, t, match }) => {
	const { id } = match.params || {};
	const [form, setForm] = useState<Partial<UserType>>({});
	const queryClient = useQueryClient();
	const { fetchWorkspaces } = useWorkspaces();
	const storeAccount = useSelector((state: any) => state.account);
	const [modalStates, setModalStates] = useState<any>({});

	const toggleModal =
		(field, value = !modalStates[field]) =>
		() => {
			setModalStates({ ...modalStates, [field]: value });
		};

	const { data: accountMfaSettings, isFetching: isFetchingAccountMfa } = useQuery({
		queryKey: [`account_${storeAccount.id}_mfa-settings`],
		queryFn: async () => {
			try {
				const res = await API.get(`/api/account/mfa_settings.json`);
				return res.data.account_mfa_settings;
			} catch (error) {
				console.error("error:", error);
				Toast.error(error);
			}
		},
	});

	const queryKey = ["user", id];
	const { data: user, isFetching } = useQuery({
		queryKey,
		queryFn: async () => {
			try {
				const res = await API.get(`/api/users/${id}.json`);

				return res.data.user;
			} catch (error) {
				console.error("error:", error);
				throw error;
			}
		},
		enabled: !!id,
	});

	const saveMutation = useMutation(
		async () => {
			const data = {
				...form,
				roles: uniq(form.roles),
			};

			const toastId = Toast.loading(t("user.responses.updating", "Uppdaterar användare"));

			return await API.put("/api/users/" + id + ".json", data)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}

					if (result.data.user.id == store.getState().user.id) {
						const user = Object.assign({}, store.getState().user);
						user.roles = result.data.user.roles;
						user.all_permissions = result.data.user.all_permissions;
						store.dispatch({ type: "SET_USER", user });

						fetchWorkspaces().catch((error) => console.error(error));

						fetchAndSetTokens().catch((error) => console.error(error));
					}

					Toast.update(toastId, {
						render: t("user.responses.updated", "Uppdaterade användare"),
						type: "success",
						isLoading: false,
						hideProgressBar: false,
						autoClose: 5000,
					});

					return result.data.user;
				})
				.catch((error) => {
					Toast.update(toastId, {
						render: error,
						type: "error",
					});
				});
		},
		{
			onSuccess: (user) => {
				if (queryKey?.length) {
					queryClient.setQueryData(queryKey, user);
					store.dispatch({ type: "UPDATE_USER", user });
				}
			},
		}
	);

	const handleSave = async () => {
		return await saveMutation.mutate();
	};

	const isSaving = saveMutation.isLoading;

	useEffect(() => {
		if (user) {
			setForm(user);
		}

		return () => {
			setForm({});
		};
	}, [user]);

	const updateForm = (field) => (value) => {
		setForm((form) => ({
			...form,
			[field]: value,
		}));
	};

	const addField = (field) => {
		const foundField = form?.fields?.find((f) => f.id == field.id);
		if (foundField) return;

		form.fields?.push(field);
		setForm({ ...form });
	};

	const removeField = (index) => {
		form?.fields?.splice(index, 1);
		setForm({ ...form });
	};

	const handleResetMfa = async () => {
		try {
			const res = await API.delete(`/api/users/${id}/otp_settings.json`);

			Toast.success(t("user.actions.mfa.reset", "MFA återställd"));
			toggleModal("removeMFA", false)();
			return res;
		} catch (error) {
			Toast.error(error);
		}
	};

	return (
		<Page
			title={form?.name || t("user.title", "Användare")}
			// loading
			backAction={{ content: t("common.actions.back", "Tillbaka"), onAction: () => history.goBack() }}
			primaryAction={{
				content: t("common.actions.update", "Uppdatera"),
				disabled: !form?.email,
				loading: isSaving,
				onAction: handleSave,
			}}
			secondaryActions={[
				{
					content: t("user.actions.show_activity", "Visa aktivitet"),
					onAction: () => {
						history.push("/admin/users_activity/user/" + id);
					},
				},
			]}
		>
			<Layout>
				<Layout.AnnotatedSection
					title={t("user.sections.details.title", "Användaruppgifter")}
					description={t("user.sections.details.description", "Fyll i användaruppgifter")}
				>
					<LegacyCard title={t("user.sections.details.card.title", "Uppgifter")}>
						{form?.id && !form?.enabled && (
							<Banner status="warning" title={t("user.errors.disabled.title", "Användarkonto avstängt")}>
								<p>{t("user.errors.disabled.description", "Användaren kan inte logga in, aktivera konto för att tillåta inloggning")}</p>
							</Banner>
						)}
						<LegacyCard.Section>
							<FormLayout>
								<Checkbox
									label={t("user.fields.enabled.label1", "Avstängd för inloggning")}
									checked={!!(form?.id && !form?.enabled)}
									onChange={(v) => {
										updateForm("enabled")(!v);
									}}
									disabled={isFetching}
								/>
								<TextField
									label={t("user.fields.name.label", "För- och efternamn")}
									value={form?.name}
									onChange={updateForm("name")}
									disabled={isFetching}
								/>
								<TextField label={t("user.fields.email.label", "E-mail")} value={form?.email} onChange={updateForm("email")} disabled={isFetching} />
								<TextField
									label={t("user.fields.phone.label", "Mobilnummer")}
									value={form?.phone}
									onChange={updateForm("phone")}
									disabled={isFetching}
								/>
								{/* <TextField
										multiline
										label={t("user.fields.email_signature.label", "E-mail signatur")}
										value={form?.email_signature}
										onChange={updateForm("email_signature")}
									/> */}
							</FormLayout>
						</LegacyCard.Section>
					</LegacyCard>
				</Layout.AnnotatedSection>

				<Layout.AnnotatedSection
					title={t("user.me.sections.require_mfa.title", "Kräv MFA")}
					// description={t("user.me.sections.sync_contacts_to_phone.description", "Synka dina kontakter/företag till din telefon vid app start")}
				>
					<LegacyCard
						title={t("user.me.sections.require_mfa.card.title", "Kräv MFA")}
						actions={[
							{
								content: t("user.actions.mfa.remove.label", "Återställ MFA") as string,
								onAction: toggleModal("removeMFA"),
								disabled: isFetching,
							},
						]}
					>
						<LegacyCard.Section>
							<FormLayout>
								<div>
									<Switch
										label={t("user.me.fields.require_mfa.label", "Kräv MFA vid inloggning")}
										checked={form.require_mfa}
										onChange={updateForm("require_mfa")}
										uncheckedIcon={undefined}
										checkedIcon={undefined}
										disabled={
											isFetching || isFetchingAccountMfa || accountMfaSettings?.require_mfa || !checkUserRoles(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"])
										}
									/>
									<Text variant="bodySm" as="p" color="subdued">
										{t(
											"user.actions.mfa.remove.desc",
											"Användaren kommer att behöva sätta upp och logga in med MFA (tvåfaktorsautentisering) vid nästa inloggning."
										)}
									</Text>
								</div>
							</FormLayout>
						</LegacyCard.Section>
					</LegacyCard>
				</Layout.AnnotatedSection>

				<Layout.AnnotatedSection
					title={t("user.sections.roles.title", "Användarrättigheter")}
					description={t("user.sections.roles.description", "Välj rättigheter åt användaren")}
				>
					<LegacyCard>
						<LegacyCard.Section>
							<UserRoles user={form} savedUser={user} onChange={updateForm("all_permissions")} userId={id} />
						</LegacyCard.Section>
					</LegacyCard>
				</Layout.AnnotatedSection>

				{store.getState().groups?.length ? (
					<Layout.AnnotatedSection
						title={t("user.sections.office_department1.title", "Avdelningar")}
						description={t("user.sections.office_department.description1", "Välj avdelning som användaren tillhör")}
					>
						<LegacyCard>
							<LegacyCard.Section title={t("user.sections.office_department.department.title", "Avdelningar")}>
								<FormLayout>
									{form?.fields?.map((field, index) => (
										<Tag key={index} onRemove={() => removeField(index)} disabled={isFetching}>
											{field.title}
										</Tag>
									))}
									<FieldSelector
										required
										empty={t("user.sections.fields.department.empty", "Välj avdelning")}
										label={t("user.sections.fields.department.label", "Lägg till avdelning")}
										field={null}
										onChange={addField}
										disabled={isFetching}
									/>
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>
				) : null}
			</Layout>
			<PageActions
				primaryAction={{
					content: t("common.actions.update", "Uppdatera"),
					disabled: !form?.email,
					loading: isSaving,
					onAction: handleSave,
				}}
			/>

			<Modal
				open={modalStates?.removeMFA}
				onClose={toggleModal("removeMFA")}
				title={t("user.actions.mfa.remove", "Återställ MFA")}
				primaryAction={{
					content: t("user.actions.mfa.remove", "Återställ MFA") as string,
					onAction: handleResetMfa,
					disabled: isFetching,
					destructive: true,
				}}
			>
				<p>
					{t(
						"user.actions.mfa.remove.description",
						"Vill du verkligen återställa MFA? Detta kommer att kräva att du ställer in MFA igen vid nästa inloggning."
					)}
				</p>
			</Modal>
		</Page>
	);
};

const mapStateToProps = (state) => ({
	users: state.users,
});

export default connect(mapStateToProps)(withTranslation(["user", "common"], { withRef: true })(UserEdit));

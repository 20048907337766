/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useImperativeHandle, forwardRef } from "react";
import { Spinner, EmptyState, Badge } from "@shopify/polaris";
import moment from "moment";
import {
	NoteMajor,
	ExportMinor,
	ProfileMajor,
	ImportMinor,
	HeartMajor,
	AddProductMajor,
	RemoveProductMajor,
	JobsMajor,
	DeleteMajor,
} from "@shopify/polaris-icons";
import { connect, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import SellerSelector from "../../components/SellerSelector.js";
import RelationshipSelector from "../../components/RelationshipSelector.js";
import ChangeRelationshipModal from "../../components/ChangeRelationshipModal.js";
import ChangeUserModal from "../../components/ChangeUserModal.js";
import UpdateContactMetafieldModal from "../../components/UpdateContactMetafieldModal.js";
import IntegrationModal from "../../components/IntegrationModal.js";
import TagModal from "../../components/tag_modal.js";
import ImportModal from "../Workspaces/components/ImportModal.js";
import IconWithBackround from "../../components/IconWithBackround";
import AddComanyModal from "../../components/AddComanyModal";
import IndexTable from "src/js/components/IndexTable";
import getDefaultFilters from "../../components/IndexTable/getDefaultFilters";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";
import ImportToDiallistModal from "../DialList/Modals/ImportToDiallistModal";
import { useHistory, useLocation } from "react-router-dom";

const ContactCompanies = forwardRef((props, ref) => {
	// States
	const [empty, setEmpty] = useState(false);
	const [firstFetch, setFirstFetch] = useState(true);
	const [uploading, setUploading] = useState(false);
	const [params, setParams] = useState({});
	const [selectedItems, setSelectedItems] = useState([]);
	const [visibleItems, setVisibleItems] = useState([]);
	const [currentContact, setCurrentContact] = useState({
		is_company: true,
		tags: [],
	});
	const [callListImportModalIsOpen, setCallListImportModalIsOpen] = useState(false);
	const [showContactModal, setShowContactModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const [showImportModal, setShowImportModal] = useState(false);
	const [showAddTagModal, setShowAddTagModal] = useState(false);
	const [showRemoveTagModal, setShowRemoveTagModal] = useState(false);
	const [showRelationshipModal, setShowRelationshipModal] = useState(false);
	const [showSellerModal, setShowSellerModal] = useState(false);
	const [currentMetafield, setCurrentMetafield] = useState(null);
	const [showUpdateContactMetafieldModal, setShowUpdateContactMetafieldModal] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [bulkRemoveModalIsOpen, setBulkRemoveModalIsOpen] = useState(false);
	const [openIntegrationModal, setOpenIntegrationModal] = useState(false);

	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation(["contact", "common"]);

	useSelector((state) => state.board_contacts);
	useSelector((state) => state.fields);
	useSelector((state) => state.contact_metafields);

	// Refs for inner components/methods
	const indexTableRef = useRef(null);

	// Expose inner methods (e.g. getFilters) to parent via ref
	useImperativeHandle(ref, () => ({
		getFilters: () => indexTableRef.current?.getFilters(),
		setFilters: ({ filters, search }) =>
			indexTableRef.current?.setFilters({
				filters,
				search,
			}),
	}));

	// Handlers & Methods
	const onSelectionChange = (newSelectedItems) => {
		setSelectedItems(newSelectedItems);
	};

	const updateParams = (newParams) => {
		setParams(newParams);
	};

	const openContactModal = (item) => {
		setShowContactModal(true);
		setCurrentContact(item);
	};

	const closeContactModal = () => {
		setShowContactModal(false);
	};

	const gotoContact = (item) => {
		item.path = `/admin/contacts/${item.id}`;
		history.push(`/admin/contacts/${item.id}`, {
			stacks: [
				{
					parent_path: location.pathname + location.search,
					parent_title: t("contact.company.title", "Företag"),
					item: { id: item.id, item, path: `/admin/contacts/${item.id}` },
					items: visibleItems,
				},
			],
		});
	};

	const exportExcel = () => {
		setShowExportModal(true);
		const newParams = { ...params, is_company: 1, is_visitor: 0 };
		API.post("/api/contacts/export.json", {}, { params: newParams });
	};

	const importExcel = () => {
		setShowImportModal(true);
	};

	const getUser = (field, user) => {
		for (let i = 0; i < user.fields.length; i++) {
			if (user.fields[i].field_id === field.id) {
				return user.fields[i].user;
			}
		}
		return null;
	};

	const getRelationship = (field, user) => {
		for (let i = 0; i < user.fields.length; i++) {
			if (user.fields[i].field_id === field.id) {
				return user.fields[i].relationship;
			}
		}
		return "none";
	};

	const changeContactRelationship = (field, contact, relationship) => {
		setSaving(true);
		API.put(`/api/contacts/${contact.id}/fields/${field.id}/relationship.json`, {
			relationship,
		})
			.then((result) => {
				Toast.success(t("contact.responses.relation.changed", "Relation ändrad"));
				indexTableRef.current.refresh(false, true);
				setSaving(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const changeContactUser = (field, contact, user) => {
		setSaving(true);
		API.put(`/api/contacts/${contact.id}/fields/${field.id}/user.json`, {
			user,
		})
			.then((result) => {
				Toast.success(t("contact.responses.contact_user.changed", "Kundansvarig ändrad"));
				indexTableRef.current.refresh(false, true);
				setSaving(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const onItemsFetched = (items) => {
		if (items?.length < 1 && firstFetch) {
			setEmpty(true);
		}
		items?.forEach((item) => {
			item.path = `/admin/contacts/${item.id}`;
		});
		setVisibleItems(items);
		setFirstFetch(false);
	};

	const getBulkParams = () => {
		if (selectedItems === "All") {
			return { ...params, is_company: 1, is_visitor: 0 };
		} else {
			return { ids: selectedItems };
		}
	};

	const toggleCallListImportModalIsOpen = (open = !callListImportModalIsOpen) => {
		setCallListImportModalIsOpen(open);
	};

	const closeAddTagModal = () => {
		setShowAddTagModal(false);
	};

	const addTags = () => {
		setShowAddTagModal(true);
	};

	const closeRemoveTagModal = () => {
		setShowRemoveTagModal(false);
	};

	const removeTags = () => {
		setShowRemoveTagModal(true);
	};

	const doAddTags = (tags) => {
		setSaving(true);
		API.post("/api/contacts/tags.json", { tags }, { params: getBulkParams() })
			.then((result) => {
				indexTableRef.current.refresh(false, true);
				Toast.success(t("contact.responses.tags.added", "La till taggar"));
				setSaving(false);
				setShowAddTagModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const doRemoveTags = (tags) => {
		setSaving(true);
		API.post("/api/contacts/remove_tags.json", { tags }, { params: getBulkParams() })
			.then((result) => {
				indexTableRef.current.refresh(false, true);
				Toast.success(t("contact.responses.tags.removed", "Tog bort taggar"));
				setSaving(false);
				setShowRemoveTagModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const closeRelationshipModal = () => {
		setShowRelationshipModal(false);
	};

	const changeRelationship = () => {
		setShowRelationshipModal(true);
	};

	const doChangeRelationship = (relationship, field) => {
		setSaving(true);
		API.put("/api/contacts/bulk_edit.json", { relationship, field }, { params: getBulkParams() })
			.then((result) => {
				indexTableRef.current.refresh(false, true);
				Toast.success(t("contact.responses.relation.changed_plural", "Ändrade relationer"));
				setSaving(false);
				setShowRelationshipModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const closeSellerModal = () => {
		setShowSellerModal(false);
	};

	const changeSeller = () => {
		setShowSellerModal(true);
	};

	const doChangeSeller = (seller, field) => {
		setSaving(true);
		API.put("/api/contacts/bulk_edit.json", { user: seller, field }, { params: getBulkParams() })
			.then((result) => {
				indexTableRef.current.refresh(false, true);
				Toast.success(t("contact.responses.customer_user.changed_plural", "Kundansvarig ändrad"));
				setSaving(false);
				setShowSellerModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	const renderCell = (item, column) => {
		if (column.handle === "NAME") {
			const exceptions = [];
			if (item.note) {
				exceptions.push({
					icon: NoteMajor,
					description: item.note,
				});
			}
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.name}</h3>
					</div>
					<div>
						{item.tags.map((prop, key) => (
							<Badge key={key}>{prop.title}</Badge>
						))}
					</div>
				</div>
			);
		} else if (column.handle === "EMAIL") {
			return item.email;
		} else if (column.handle === "PHONE") {
			return item.phone;
		} else if (column.handle === "CITY") {
			return item.city;
		} else if (column.handle === "LAST_ACTIVITY") {
			return item.last_activity_at ? moment(item.last_activity_at).locale("sv").calendar() : "–";
		} else if (column.handle === "LAST_SESSION") {
			return item.last_session_at ? moment(item.last_session_at).locale("sv").calendar() : "–";
		} else if (column.handle.startsWith("RELATIONSHIP_")) {
			return (
				<RelationshipSelector
					relationship={getRelationship(column.field, item)}
					onChange={(relationship) => changeContactRelationship(column.field, item, relationship)}
				/>
			);
		} else if (column.handle.startsWith("USER_")) {
			return <SellerSelector user={getUser(column.field, item)} onChange={(user) => changeContactUser(column.field, item, user)} clearable />;
		} else if (column.handle.substring(0, 10) === "METAFIELD_") {
			const metafieldId = parseInt(column.handle.substring(10));
			let metaField = null;
			const contactMetafields = store.getState().contact_metafields;
			for (let i = 0; i < contactMetafields.length; i++) {
				if (contactMetafields[i].id === metafieldId) {
					metaField = contactMetafields[i];
					break;
				}
			}
			if (metaField) {
				for (let i = 0; i < item.metafields.length; i++) {
					if (item.metafields[i].metafield_id === metafieldId) {
						return item.metafields[i].value;
					}
				}
			}
			return "–";
		}
		return column.handle;
	};

	const closeUpdateContactMetafieldModal = () => {
		setShowUpdateContactMetafieldModal(false);
	};

	const updateMetafield = (metafield) => {
		setCurrentMetafield(metafield);
		setShowUpdateContactMetafieldModal(true);
	};

	const bulkRemoveContacts = async (ids) => {
		try {
			if (!ids?.length) {
				throw new Error("No contacts selected");
			}
			setRemoving(true);
			const result = await API.delete("/api/contacts.json", {
				data: { ids },
			});
			indexTableRef.current.refresh();
			Toast.success(t("contact.responses.removed", "Tog bort {{count}}st företag och deras kontakter", { count: result.data.count }));
			setBulkRemoveModalIsOpen(false);
		} catch (error) {
			Toast.error(error);
		} finally {
			setRemoving(false);
		}
	};

	const doUpdateContactMetafield = (value) => {
		setSaving(true);
		API.put("/api/contacts/bulk_edit_metafield.json", { metafield: currentMetafield, value }, { params: getBulkParams() })
			.then((result) => {
				indexTableRef.current.refresh();
				Toast.success(t("contact.responses.metafield.changed", "Ändrade {{field}}", { field: currentMetafield.title }));
				setSaving(false);
				setShowUpdateContactMetafieldModal(false);
			})
			.catch((error) => {
				setSaving(false);
				Toast.error(error);
			});
	};

	// Build filters, bulk actions and columns
	const filters = getDefaultFilters("companies");

	const bulkActions = [
		{
			items: [
				{
					icon: ProfileMajor,
					content: t("contact.actions.customer.change", "Ändra kundansvarig"),
					onAction: changeSeller,
				},
				{
					icon: HeartMajor,
					content: t("contact.actions.relation.change", "Ändra relation"),
					onAction: changeRelationship,
				},
			],
		},
		{
			items: [
				{
					icon: AddProductMajor,
					content: t("contact.actions.tags.add", "Lägg till taggar"),
					onAction: addTags,
				},
				{
					icon: RemoveProductMajor,
					content: t("contact.actions.tags.remove", "Ta bort taggar"),
					onAction: removeTags,
				},
			],
		},
		{
			items: [
				{
					icon: ImportMinor,
					content: t("contact.actions.import.dial_entry", "Importera till ringlista"),
					onAction: () => toggleCallListImportModalIsOpen(),
				},
			],
		},
	];

	const columns = [
		{
			handle: "NAME",
			label: t("contact.columns.name.label", "Namn"),
			sortable: true,
			type: "text",
		},
		{
			handle: "EMAIL",
			label: t("contact.columns.email.label", "E-post"),
			sortable: true,
			type: "text",
		},
		{
			handle: "PHONE",
			label: t("contact.columns.phone.label", "Telefon"),
			sortable: true,
			type: "text",
		},
	];

	store.getState().fields.forEach((field) => {
		columns.push({
			handle: `USER_${field.id}`,
			field,
			label: t("contact.columns.contact_user.label", "Kundansvarig") + (store.getState().fields.length > 1 ? " " + field.title : ""),
			sortable: false,
			type: "text",
		});
		columns.push({
			handle: `RELATIONSHIP_${field.id}`,
			field,
			label: t("contact.columns.relation.label", "Relation") + (store.getState().fields.length > 1 ? " " + field.title : ""),
			sortable: false,
			type: "text",
		});
	});

	columns.push({
		handle: "CITY",
		label: t("contact.columns.city.label2", "Ort"),
		sortable: true,
		type: "text",
	});
	columns.push({
		handle: "LAST_ACTIVITY",
		label: t("contact.columns.last_activity.label1", "Senaste aktivitet"),
		sortable: true,
		type: "text",
	});
	columns.push({
		handle: "ORGNR",
		label: t("contact.columns.orgnr.label", "Org nr"),
		type: "text",
		render: (item) => item.orgnr,
	});
	columns.push({
		handle: "CUSTOMER_NUMBER",
		label: t("contact.columns.customer_number.label", "Kundnummer"),
		type: "text",
		render: (item) => item.customer_number,
	});

	const metafieldActions = { items: [] };

	store.getState().contact_metafields.forEach((metafield) => {
		if (metafield.for === "company") {
			columns.push({
				handle: "METAFIELD_" + metafield.id,
				label: metafield.title,
				sortable: false,
				type: "text",
			});
			metafieldActions.items.push({
				icon: AddProductMajor,
				content: metafield.title,
				onAction: () => updateMetafield(metafield),
			});
		}
	});

	if (store.getState().contact_metafields.length) {
		bulkActions.push(metafieldActions);
	}

	if (store.getState().user.roles?.includes("ROLE_ADMIN")) {
		bulkActions.push({
			items: [
				{
					icon: DeleteMajor,
					content: t("contact.actions.remove", "Ta bort"),
					onAction: () => {
						setBulkRemoveModalIsOpen(true);
					},
					destructive: true,
					loading: removing,
				},
			],
		});
	}

	const defaultParams = { is_company: 1, is_visitor: 0 };
	if (props.company?.id) {
		defaultParams.parent_id = props.company.id;
	}

	return (
		<div>
			<IndexTable
				urlParamsEnabled={props.urlParamsEnabled}
				params={defaultParams}
				ref={indexTableRef}
				inline={props.inline}
				history={history}
				title={t("contact.company.title", "Företag")}
				secondaryActions={
					!props.inline && store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0
						? [
								{
									icon: ExportMinor,
									content: t("contact.actions.export", "Exportera"),
									onAction: exportExcel,
								},
								{
									icon: ImportMinor,
									content: uploading ? <Spinner size="small" color="teal" /> : t("contact.actions.import", "Importera"),
									onAction: importExcel,
								},
						  ]
						: []
				}
				primaryAction={{
					content: t("contact.company.actions.new", "Nytt företag"),
					onAction: () =>
						openContactModal({
							is_company: true,
							name: "",
							company: "",
							email: "",
							phone: "",
							zip: "",
							note: "",
							address1: "",
							address2: "",
							country: "SE",
							tags: [],
						}),
				}}
				filterKey="companies"
				savedSearchHandle="contact_companies"
				resourceUrl="/api/contacts_verbose.json"
				resourceHandle="contacts"
				onItemsFetched={onItemsFetched}
				onSelectionChange={onSelectionChange}
				resourceName={{
					singular: t("contact.company.singular", "företag"),
					plural: t("contact.company.plural", "företag"),
				}}
				selectedItems={selectedItems}
				route
				onUpdateParams={updateParams}
				renderCell={renderCell}
				filters={filters}
				columns={columns}
				defaultSort="LAST_ACTIVITY_DESC"
				promotedBulkActions={[]}
				onClickRow={gotoContact}
				bulkActions={bulkActions}
				emptyState={
					empty && (
						<div>
							<EmptyState
								heading={t("contact.company.empty.heading", "Inga företag")}
								action={{
									content: t("contact.company.actions.create", "Skapa företag"),
									onAction: () =>
										openContactModal({
											is_company: true,
											name: "",
											company: "",
											email: "",
											phone: "",
											zip: "",
											note: "",
											address1: "",
											address2: "",
											country: "SE",
											tags: [],
										}),
								}}
								image="/assets/images/empty_state/Company.png"
							>
								<p>{t("contact.company.empty.description", "Du har inga företag att visa")}</p>
							</EmptyState>
							<AddComanyModal
								history={history}
								open={showContactModal}
								contact={currentContact}
								onUpdate={() => {
									setEmpty(false);
								}}
								onClose={closeContactModal}
								onCreate={(contact) => {
									gotoContact(contact);
								}}
								isCompany
							/>
						</div>
					)
				}
			/>
			<AddComanyModal
				history={history}
				open={showContactModal}
				onUpdate={() => {
					indexTableRef.current.refresh();
				}}
				contact={currentContact}
				onCreate={(contact) => {
					gotoContact(contact);
				}}
				isCompany
				onClose={closeContactModal}
			/>
			<TagModal
				open={showAddTagModal}
				onClose={closeAddTagModal}
				resourceName={{
					singular: t("contact.singular", "kontakt"),
					plural: t("contact.plural", "kontakter"),
				}}
				resource="contacts"
				numItems={selectedItems.length}
				onConfirm={doAddTags}
				loading={saving}
			/>
			<TagModal
				open={showRemoveTagModal}
				onClose={closeRemoveTagModal}
				resourceName={{
					singular: t("contact.singular", "kontakt"),
					plural: t("contact.plural", "kontakter"),
				}}
				resource="contacts"
				numItems={selectedItems.length}
				onConfirm={doRemoveTags}
				loading={saving}
				remove
			/>
			<ChangeRelationshipModal
				open={showRelationshipModal}
				loading={saving}
				onClose={closeRelationshipModal}
				onConfirm={doChangeRelationship}
				numItems={selectedItems.length}
			/>
			<ChangeUserModal
				open={showSellerModal}
				loading={saving}
				onClose={closeSellerModal}
				onConfirm={doChangeSeller}
				numItems={selectedItems.length}
			/>
			<UpdateContactMetafieldModal
				open={showUpdateContactMetafieldModal}
				loading={saving}
				onClose={closeUpdateContactMetafieldModal}
				onConfirm={doUpdateContactMetafield}
				numItems={selectedItems.length}
				metafield={currentMetafield}
			/>
			<ImportModal
				open={showImportModal}
				type="company"
				onClose={() => {
					setShowImportModal(false);
				}}
				onComplete={() => {
					indexTableRef.current.refresh();
				}}
			/>
			<IntegrationModal
				open={openIntegrationModal}
				category="accounting"
				onCreate={(type) => {}}
				onClose={() => {
					setOpenIntegrationModal(false);
				}}
			/>
			<Modal
				open={showExportModal}
				onClose={() => {
					setShowExportModal(false);
				}}
				title={t("contact.export.companies.title", "Exportera företag")}
				primaryAction={{
					content: t("buttons.close", "Stäng"),
					onAction: () => {
						setShowExportModal(false);
					},
				}}
			>
				<Modal.Section>
					<p>{t("contact.export.please_wait", "Din export är på väg. Du kommer att få ett mail när den är klar.")}</p>
				</Modal.Section>
			</Modal>
			<Modal
				sectioned
				title={t("contacts.actions.bulk_remove.confirm.title", `Ta bort {{count}}st företag och deras kontakter?`, {
					count: indexTableRef.current?.getSelectedItems()?.length,
				})}
				open={bulkRemoveModalIsOpen}
				onClose={() => {
					setBulkRemoveModalIsOpen(false);
				}}
				secondaryActions={[
					{
						content: t("common.actions.cancel", "Avbryt"),
						onAction: () => {
							setBulkRemoveModalIsOpen(false);
						},
					},
				]}
				primaryAction={{
					content: t("common.actions.remove", "Ta bort"),
					loading: removing,
					onAction: () => {
						bulkRemoveContacts(indexTableRef.current?.getSelectedItems());
					},
				}}
			>
				<p>
					{t("contacts.actions.bulk_remove.confirm.text", "	Säker på att du vill ta bort {{count}}st företag och deras kontakter?", {
						count: indexTableRef.current?.getSelectedItems()?.length,
					})}
				</p>
			</Modal>
			<ImportToDiallistModal
				isOpen={callListImportModalIsOpen}
				onSuccess={() => {
					setCallListImportModalIsOpen(false);
					indexTableRef.current?.clearSelection();
				}}
				onClose={() => {
					toggleCallListImportModalIsOpen(false);
				}}
				contactIds={indexTableRef.current?.getSelection()}
			/>
		</div>
	);
});

const mapStateToProps = (state, ownProps) => ({
	board_companies: state.board_companies,
	fields: state.fields,
	contact_metafields: state.contact_metafields,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(ContactCompanies);

import React, { Suspense, useEffect, useRef, useState } from "react";
import { PageActions, Layout, LegacyCard, FormLayout, InlineError, LegacyStack, Tooltip, Select, Text } from "@shopify/polaris";
import { DeleteMajor, EditMajor } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Page from "src/js/components/page";
import { fetchAndSetTokens } from "src/js/API/API";
import ReactQuill from "react-quill";
import styled from "styled-components";
import API from "../../API";
import { store } from "../../store";

import ProfileAvatar from "../../components/ProfileAvatar.js";
import UploadModal from "../../components/UploadModal.js";
import EmailSignatureModal from "./EmailSignatureModal";
import Switch from "src/js/components/Switch";
import Toast from "src/js/components/Toast";
import useQuery from "src/js/hooks/useQuery";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TextField from "src/js/components/TextField";
import Button from "src/js/components/Button";
import SkeletonPage from "src/js/components/Skeletons/Page";
import Modal from "src/js/components/modal";

const MyPage = () => {
	const { t, i18n } = useTranslation(["user", "common"]);
	const storeUser = useSelector((state: any) => state.user);
	const storeAccount = useSelector((state: any) => state.account);
	const [form, setForm] = useState<UserType | any>(storeUser || {});
	const [modalStates, setModalStates] = useState<any>({});
	const toastId = useRef<any>(null);

	const queryClient = useQueryClient();

	const { data: accountMfaSettings, isFetching: isFetchingAccountMfa } = useQuery({
		queryKey: [`account_${storeAccount.id}_mfa-settings`],
		queryFn: async () => {
			try {
				const res = await API.get(`/api/account/mfa_settings.json`);
				return res.data.account_mfa_settings;
			} catch (error) {
				console.error("error:", error);
				Toast.error(error);
			}
		},
	});

	const queryKey = ["user", "me", storeUser?.id];
	const { data: user, isFetching } = useQuery({
		queryKey,
		queryFn: async () => {
			toastId.current = Toast.loading(t("user.me.responses.loading", "Laddar..."));

			try {
				const res = await API.get("/api/me.json");
				Toast.dismiss(toastId.current);
				return res.data.user;
			} catch (e) {
				Toast.update(toastId.current, { type: "error", render: e });
			} finally {
				Toast.dismiss(toastId.current);
			}
		},
		// initialData: storeUser,
	});

	const canRemoveProfileImage = user?.avatar;

	useEffect(() => {
		if (user) {
			setForm(user);
		}

		return () => {
			Toast.dismiss(toastId.current);
		};
	}, [user]);

	const { data: languages } = useQuery({
		queryKey: ["translations"],
		queryFn: async () => {
			const res = await API.get("/api/translations.json");
			return res.data.languages;
		},
	});

	const localeOptions = [
		{
			label: t("user.me.sections.language.fields.locale.options.choose.label", "Välj språk"),
			value: "",
			disabled: true,
		},
		...(languages || []).map((language) => {
			return {
				label: t("common.locale." + language.locale + ".label", language.title),
				value: language.locale,
			};
		}),
	];

	const getChangeHandler = (field) => (value) => {
		setForm({ ...form, [field]: value });
	};

	const saveMutation = useMutation(
		async (userForm = form) => {
			const result = await API.put("/api/me.json", userForm).then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				const user = { ...storeUser, ...result.data.user };
				store.dispatch({ type: "SET_USER", user });

				if (form.locale !== result.data.user?.locale) {
					// window.location.href = encodeURI("/admin/authentication/login?error_msg=Login for language change to take effect");
					// const refreshToken = store.getState().refresh_token || getSessionStorage("refresh_token") || getLocalStorage("refresh_token");

					i18n.changeLanguage(user?.locale, () => {
						// eslint-disable-next-line no-console
						console.log(`Changing language to "${user?.locale}" from userpage.`);
					});

					fetchAndSetTokens().catch((error) => console.error(error));
				}

				return result.data?.user;
			});

			return result;
		},
		{
			onSuccess: (data) => {
				if (data && queryKey) {
					queryClient.setQueriesData(queryKey, data);
				}
			},
			onError: (error) => {
				Toast.error(error);
			},
		}
	);

	const handleSave = async (data = form) => {
		const toastId = Toast.loading(t("user.me.responses.saving", "Sparar..."));
		try {
			const res = await saveMutation.mutateAsync(data);
			Toast.update(toastId, { type: "success", render: t("user.me.responses.updated", "Uppdaterade dina uppgifter") });
			return res;
		} catch (error) {
			Toast.update(toastId, { type: "error", render: error });
		}
	};

	const handleResetMfa = async () => {
		try {
			const res = await API.delete("/api/me/otp_settings.json");

			Toast.success(t("user.actions.mfa.reset", "MFA återställd"));
			toggleModal("removeMFA", false)();
			return res;
		} catch (error) {
			Toast.error(error);
		}
	};

	const toggleModal =
		(field, value = !modalStates[field]) =>
		() => {
			setModalStates({ ...modalStates, [field]: value });
		};

	const removeProfileImage = () => {
		setForm({ ...form, avatar: "" });
		toggleModal("openUploadImage", false)();
		handleSave();
	};

	// openEmailSignatureModal() {
	// 	this.setState({ openEmailSignature: true });
	// }

	// closeEmailSignatureModal() {
	// 	this.setState({ openEmailSignature: false });
	// }

	const isSaving = saveMutation.isLoading;
	const newPasswordInvalid = form.password && form.password2 && form.password !== form.password2;

	const primaryAction = {
		content: t("common.actions.save", "Spara") as string,
		disabled: !form.email || isFetching || isSaving,
		loading: isSaving,
		onAction: () => handleSave(form),
	};

	// if (isFetching) return <SkeletonPage title={t("user.me.title", "Mina sidor") as string} />;
	return (
		<Suspense fallback={<SkeletonPage title={t("user.me.title", "Mina sidor") as string} />}>
			<Page title={t("user.me.title", "Mina sidor")} loading={isFetching} primaryAction={primaryAction}>
				<Layout>
					<Layout.AnnotatedSection title={t("user.me.sections.details.title", "Mina uppgifter")} description="">
						<LegacyCard title={t("user.me.sections.details.card.title", "Uppgifter")}>
							<LegacyCard.Section>
								<FormLayout>
									<LegacyStack alignment="center" distribution="equalSpacing">
										<ProfileAvatar
											onClick={toggleModal("openUploadImage")}
											user={form}
											tooltip={t("user.me.fields.profile.tooltip", "Ändra profil bild")}
											size={50}
										/>
										<Tooltip content={t("user.me.fields.profile.tooltip", "Ändra profil bild")}>
											<Button onClick={toggleModal("openUploadImage")} icon={EditMajor} disabled={isFetching}>
												{t("common.actions.change", "Ändra")}
											</Button>
										</Tooltip>
									</LegacyStack>
									<TextField
										disabled={isFetching}
										label={t("user.me.fields.name.label", "För- och efternamn")}
										value={form.name}
										onChange={getChangeHandler("name")}
									/>
									<TextField
										disabled={isFetching}
										label={t("user.me.fields.email.label", "E-mail")}
										value={form.email}
										onChange={getChangeHandler("email")}
									/>
									<TextField
										disabled={isFetching}
										label={t("user.me.fields.phone.label", "Mobilnummer")}
										value={form.phone}
										onChange={getChangeHandler("phone")}
									/>
									<TextField
										disabled={isFetching}
										type="password"
										label={t("user.me.fields.old_password.label", "Nuvarade lösenord")}
										value={form.old_password}
										onChange={getChangeHandler("old_password")}
									/>
									<TextField
										disabled={isFetching}
										type="password"
										label={t("user.me.fields.password.label", "Nytt lösenord")}
										value={form.password}
										onChange={getChangeHandler("password")}
										error={newPasswordInvalid}
									/>
									{newPasswordInvalid && (
										<InlineError message={t("user.me.fields.password.error", "Password do not match") as string} fieldID="passwordField" />
									)}
									<TextField
										disabled={isFetching}
										type="password"
										label={t("user.me.fields.password2.label", "Nytt lösenord igen")}
										value={form.password2}
										onChange={getChangeHandler("password2")}
										error={newPasswordInvalid}
									/>
									{newPasswordInvalid && (
										<InlineError message={t("user.me.fields.password.error", "Password do not match") as string} fieldID="password2Field" />
									)}
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection
						title={t("user.fields.description.label", "Beskrivning")}
						description={t(
							"user.fields.description.description",
							"Här kan du skriva en beskrivning om dig själv och dina uppgifter för att systemet ska kunna hjälpa dig bättre"
						)}
					>
						<LegacyCard title={t("user.fields.description.card.title", "Beskrivning")} sectioned>
							<TextField disabled={isFetching} value={form.description} onChange={getChangeHandler("description")} multiline={4} />
						</LegacyCard>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection
						title={t("user.me.sections.email_signature.title1", "E-postsignatur")}
						description={t(
							"user.me.sections.email_signature.description2",
							"Sätt din E-postsignatur som kommer användas i systemet när du skickar epost ifrån en kund"
						)}
					>
						<LegacyCard
							title={t("user.me.sections.email_signature.card.title1", "E-postsignatur")}
							actions={[
								{
									content: (form.email_signature ? t("common.actions.edit", "Redigera") : t("common.actions.add", "Lägg till")) as string,
									onAction: toggleModal("emailSignatureModal"),
									disabled: isFetching,
								},
							]}
						>
							<LegacyCard.Section>
								<FormLayout>
									{form.email_signature && (
										<SignatureWrapper onClick={toggleModal("emailSignatureModal")}>
											<ReactQuill className="slim" modules={{ toolbar: false }} value={form.email_signature} theme="snow" readOnly />
										</SignatureWrapper>
									)}
									{!form.email_signature && (
										<Button primary onClick={toggleModal("emailSignatureModal")}>
											{t("common.actions.add", "Lägg till")}
										</Button>
									)}
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection
						title={t("user.me.sections.language.title", "Språk")}
						description={t("user.me.sections.language.description2", "Välj ditt föredragna språk som kommer användas i systemet")}
					>
						<LegacyCard title={t("user.me.sections.language.card.title", "Språk")}>
							<LegacyCard.Section>
								<FormLayout>
									<Select
										label={t("user.me.fields.language.label", "Språk")}
										options={localeOptions}
										value={form.locale || ""}
										onChange={getChangeHandler("locale")}
										disabled={isFetching}
									/>
									{store.getState().user.locale !== form.locale && (
										<p style={{ opacity: 0.75, fontSize: "0.9em" }}>{t("user.me.fields.language.helptext2", "Du kommer behöva logga in på nytt")}</p>
									)}
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection
						title={t("user.me.sections.sync_contacts_to_phone.title", "Synka kontakter till telefon")}
						description={t("user.me.sections.sync_contacts_to_phone.description", "Synka dina kontakter/företag till din telefon vid app start")}
					>
						<LegacyCard title={t("user.me.sections.sync_contacts_to_phone.card.title", "Synka kontakter till telefon")}>
							<LegacyCard.Section>
								<FormLayout>
									<Switch
										label={t("user.me.fields.sync_contacts_to_phone.label", "Synka kontakter till telefon")}
										checked={["ALL", "MINE"].includes(form.sync_contacts_to_phone)}
										onChange={(checked) => getChangeHandler("sync_contacts_to_phone")(checked ? "MINE" : "NONE")}
										uncheckedIcon={undefined}
										checkedIcon={undefined}
										disabled={isFetching}
									/>

									<Switch
										label={t("user.me.fields.sync_contacts_to_phone2.label", "Synka alla kontakter, inte bara de jag är kundansvarig för")}
										checked={form.sync_contacts_to_phone === "ALL"}
										onChange={(checked) => {
											getChangeHandler("sync_contacts_to_phone")(checked ? "ALL" : "MINE");
										}}
										uncheckedIcon={undefined}
										checkedIcon={undefined}
										disabled={!form.sync_contacts_to_phone || form.sync_contacts_to_phone === "NONE" || isFetching}
									/>
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection
						title={t("user.me.sections.require_mfa.title", "Kräv MFA")}
						// description={t("user.me.sections.sync_contacts_to_phone.description", "Synka dina kontakter/företag till din telefon vid app start")}
					>
						<LegacyCard
							title={t("user.me.sections.require_mfa.card.title", "Kräv MFA")}
							actions={[
								{
									content: t("user.actions.mfa.remove", "Återställ MFA") as string,
									onAction: toggleModal("removeMFA"),
									disabled: isFetching,
								},
							]}
						>
							<LegacyCard.Section>
								<FormLayout>
									<div>
										<Switch
											label={t("user.me.fields.require_mfa.label", "Kräv MFA vid inloggning")}
											checked={form.require_mfa}
											onChange={getChangeHandler("require_mfa")}
											uncheckedIcon={undefined}
											checkedIcon={undefined}
											disabled={isFetching || isFetchingAccountMfa || accountMfaSettings?.require_mfa}
										/>
										<Text variant="bodySm" as="p" color="subdued">
											{t(
												"user.actions.mfa.remove.desc",
												"Du kommer att behöva sätta upp och logga in med MFA (tvåfaktorsautentisering) vid nästa inloggning."
											)}
										</Text>
									</div>
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>
				</Layout>

				<UploadModal
					disabledToast
					open={modalStates?.openUploadImage}
					onClose={toggleModal("openUploadImage")}
					onSelect={(imgs) => {
						if (imgs[0] && imgs[0].url) {
							form.avatar = imgs[0].url;
							setForm({ ...form });
							toggleModal("openUploadImage", false)();
							handleSave();
						}
					}}
					secondaryActions={
						canRemoveProfileImage && [
							{
								content: t("user.actions.profile.remove", "Ta bort profilbild"),
								onAction: removeProfileImage,

								destructive: true,
								icon: DeleteMajor,
							},
						]
					}
					allowMultiple={false}
					as="profile"
				/>
				<PageActions primaryAction={primaryAction} />
				<EmailSignatureModal
					emailSignature={form.email_signature}
					open={modalStates?.emailSignatureModal}
					onClose={toggleModal("emailSignatureModal")}
					onComplete={(emailSignature) => {
						const data = { ...form, email_signature: emailSignature };
						setForm(data);
						toggleModal("emailSignatureModal", false)();
						handleSave(data);
					}}
				/>
				<Modal
					open={modalStates?.removeMFA}
					onClose={toggleModal("removeMFA")}
					title={t("user.actions.mfa.remove", "Återställ MFA")}
					primaryAction={{
						content: t("user.actions.mfa.remove", "Återställ MFA") as string,
						onAction: handleResetMfa,
						disabled: isFetching,
						destructive: true,
					}}
				>
					<p>
						{t(
							"user.actions.mfa.remove.description",
							"Vill du verkligen återställa MFA? Detta kommer att kräva att du ställer in MFA igen vid nästa inloggning."
						)}
					</p>
				</Modal>
			</Page>
		</Suspense>
	);
};

export default MyPage;

const SignatureWrapper = styled.div<{ onClick?: any }>`
	max-width: 500px;
	cursor: pointer;

	* {
		pointer-events: none;
	}
`;

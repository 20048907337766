// import Login from "./login/Login";
import PreApp from "./PreApp.js";
import GuestView from "../guest";
import SplashLoadingScreen from "./SplashLoadingScreen.tsx";
import Routes from "./login/Routes";

const parentRoutes = [
	{
		path: "/admin/authentication/login?token=:token",
		component: PreApp,
		name: "Login in",
	},
	{
		path: "/admin/authentication/login?refresh_token=:refresh_token",
		component: PreApp,
		name: "Login in",
	},
	{
		path: "/admin/authentication/login/:action?",
		component: Routes,
		name: "Login",
	},
	{ path: "/admin", component: PreApp },
	{ path: "/guest", name: "Guest", component: GuestView },
	{ path: "/api", name: "Invalid path", component: SplashLoadingScreen },
	{ path: "/", redirect: "/admin", enabled: process.env.NODE_ENV === "development", component: SplashLoadingScreen },
].filter((route) => !("enabled" in route) || route.enabled);

export default parentRoutes;

import React, { useEffect, useState } from "react";
import { FormWrapper, LeftWrapper, QrCodeImage, QrCodeWrapper, RightWrapper, Wrapper } from "../styles2";
import { useLoginContext } from "../LoginContext";
import useQuery from "src/js/hooks/useQuery";
import API from "src/js/API";
import OTPInput from "../Components/OTPInput";
import { useHistory } from "react-router-dom";
import { getErrorMessageString } from "src/js/components/Toast";
import { Text } from "@shopify/polaris";

const OTPSetup = () => {
	const { tempToken, handleJwtLogin } = useLoginContext();
	const [error, setError] = useState("");
	const history = useHistory();

	useEffect(() => {
		if (!tempToken) {
			history.push("/authentication/login");
		}
	}, [history, tempToken]);

	const { data: qrCode } = useQuery({
		queryKey: ["otp-setup", tempToken],
		queryFn: async () => {
			const res = await API.get(`/api/otp/setup.json`, {
				headers: {
					Authorization: `Bearer ${tempToken}`,
				},
			});

			return res.data.qr_code;
		},
		enabled: !!tempToken,
	});

	const handleVerify = async (otp: string) => {
		setError("");

		try {
			const res = await API.post(
				`/api/otp/verify.json`,
				{ otp_code: otp },
				{
					headers: {
						Authorization: `Bearer ${tempToken}`,
					},
				}
			);

			handleJwtLogin({
				token: res.data.token,
				refreshToken: res.data.refresh_token,
				// rememberMeChecked: true,
			});
		} catch (error) {
			console.error("Error verifying OTP:", error);
			const errorMessage = getErrorMessageString(error);
			setError(`Ett fel uppstod vid verifiering av OTP. \n ${errorMessage}`);
		}
	};

	if (!tempToken) return null;

	return (
		<Wrapper>
			<LeftWrapper>
				<h1>Kom igång med 2FA</h1>
			</LeftWrapper>

			<RightWrapper>
				<FormWrapper style={{ width: "max-content", minWidth: "auto" }}>
					<QrCodeWrapper>
						<QrCodeImage alt="qr_code" src={qrCode} />

						<Text variant="bodyMd" as="p" fontWeight="bold">
							Din organisation eller du själv kräver att <br /> 2FA (tvåfaktorsautentisering) används vid inloggning.
						</Text>

						<div>
							<Text variant="bodySm" as="p">
								Skanna QR-koden med din autentiseringsapp,
								<br /> skriv sen in din OTP-kod ifrån appen i fältet nedan.
							</Text>

							<br />
							<Text variant="bodySm" as="p">
								{"Exempel på autentiseringsappar är "}
								<b>Google Authenticator</b>
								{", "}
								<b>Authy</b>
								{" eller "}
								<b>Microsoft Authenticator</b>.
							</Text>
						</div>
					</QrCodeWrapper>
					<OTPInput onComplete={handleVerify} error={error} />
				</FormWrapper>
			</RightWrapper>
		</Wrapper>
	);
};
export default OTPSetup;

import { InlineError } from "@shopify/polaris";
import React, { useState, useRef, ChangeEvent, KeyboardEvent, ClipboardEvent, useEffect } from "react";
import Button from "src/js/components/Button";
import styled from "styled-components";

type OTPInputProps = {
	numInputs?: number; // Number of OTP input boxes (default is 6)
	onChange?: (otp: string) => void; // Callback when OTP value changes
	onComplete: (otp: string) => Promise<void> | void; // Callback when all inputs are filled
	inputStyle?: React.CSSProperties; // Optional custom style for input boxes
	containerStyle?: React.CSSProperties; // Optional custom style for the container
	showVerifyButton?: boolean;
	error?: string; // Error message to display
};

const OTPInput: React.FC<OTPInputProps> = ({ numInputs = 6, onChange, onComplete, inputStyle, containerStyle, showVerifyButton = true, error }) => {
	// State to store OTP digits
	const [otp, setOtp] = useState<string[]>(new Array(numInputs).fill(""));
	const [loading, setLoading] = useState(false);
	// Refs to manage focus on the input elements
	const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

	const handleComplete = async (otpStr: string) => {
		setLoading(true);
		try {
			await onComplete(otpStr);
		} catch (error) {
			console.error("Error completing OTP:", error);
		} finally {
			setLoading(false);
		}
	};

	// Handle input change for each box
	const handleChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
		let value = event.target.value;
		// Only allow one character per box
		if (value.length > 1) {
			value = value.charAt(value.length - 1);
		}
		// Update the OTP array
		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);

		// Notify the parent component about the change
		onChange?.(newOtp.join(""));

		// Move focus to the next input if a value was entered
		if (value !== "" && index < numInputs - 1) {
			inputsRef.current[index + 1]?.focus();
		}

		// Trigger the onComplete callback if all boxes are filled
		if (newOtp.every((val) => val !== "")) {
			handleComplete(newOtp.join(""));
		}
	};

	// Handle key down events for navigation (e.g., backspace)
	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
		if (event.key === "Backspace") {
			if (otp[index] === "" && index > 0) {
				inputsRef.current[index - 1]?.focus();
			}
		}
	};

	// Handle paste event to allow filling all boxes at once
	const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
		event.preventDefault();
		const pastedData = event.clipboardData.getData("text");
		// Extract only digits and limit to numInputs
		const digits = pastedData.replace(/\D/g, "").slice(0, numInputs).split("");
		if (digits.length > 0) {
			// Fill the OTP array with the pasted digits (remaining boxes will be empty)
			const newOtp = new Array(numInputs).fill("");
			for (let i = 0; i < digits.length; i++) {
				newOtp[i] = digits[i];
			}
			setOtp(newOtp);
			onChange?.(newOtp.join(""));

			// Move focus to the next empty input or the last input
			inputsRef.current[Math.min(digits.length, numInputs - 1)]?.focus();

			// If all inputs are filled, trigger onComplete
			if (newOtp.every((val) => val !== "")) {
				handleComplete(newOtp.join(""));
			}
		}
	};

	useEffect(() => {
		// Focus the first input when the component mounts
		inputsRef.current[0]?.focus();
	}, []);

	useEffect(() => {
		//clear all the inputs
		if (error) {
			setOtp(new Array(numInputs).fill(""));
			onChange?.("");

			setTimeout(() => {
				inputsRef.current[0]?.focus();
			}, 0);
		}
	}, [error, numInputs, onChange]);

	const verifyDisabled = !otp || otp.length !== numInputs || otp.some((digit) => digit === "");

	return (
		<Wrapper>
			<div style={{ display: "flex", gap: "0.5rem", ...containerStyle }}>
				{otp.map((data, index) => (
					<InputBox
						disabled={loading}
						autoFocus={index === 0}
						key={index}
						type="text"
						name={`otp-${index}`}
						maxLength={1}
						value={data}
						onChange={(e) => handleChange(e, index)}
						onKeyDown={(e) => handleKeyDown(e, index)}
						onPaste={handlePaste}
						ref={(el) => {
							inputsRef.current[index] = el;
						}}
						style={{
							...inputStyle,
						}}
					/>
				))}
			</div>
			{showVerifyButton && (
				<Button primary loading={loading} disabled={verifyDisabled} onClick={async () => await handleComplete(otp.join(""))} fullWidth>
					Fortsätt
				</Button>
			)}
			{error && <InlineError message={error} fieldID="otp-error" />}
		</Wrapper>
	);
};

export default OTPInput;

const InputBox = styled.input`
	width: 3rem;
	height: 4rem;
	font-size: 1.5rem;
	text-align: center;
	border: 2px solid var(--main4);

	&:focus-visible {
		/* border-color: var(--accent); */
		/* outline-color: transparent;
		outline-style: hidden;
		outline-width: 0; */
		outline: thin solid var(--accent);
	}
`;

const Wrapper = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
	width: max-content;

	.Polaris-Icon {
		margin: 0;
	}
`;

/* eslint-disable no-unreachable */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import "./login.scss";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { decodeTokenUser, getSubdomain } from "../API/utils";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AddCookie, setSessionStorage } from "../Utilities";
import BASE_URL from "../API/BASE_URL";
import clearLogin from "./clearLogin";
import useQuery from "../hooks/useQuery";
import Spinner from "../components/Spinner";
import FindAccountModal from "./FindAccountModal";
// import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { Form, FormWrapper, LeftWrapper, RightWrapper, Wrapper } from "./styles2";
import { useLoginContext } from "./LoginContext";

const Login = () => {
	const { setTempToken, handleJwtLogin } = useLoginContext();
	const params = new URLSearchParams(window.location.search);
	const history = useHistory();

	const [loading, setLoading] = useState();
	const [error, setError] = useState(String(params.get("error_msg")) === "undefined" ? null : params.get("error_msg"));
	const [rememberMeChecked, setRememberMeChecked] = useState(true);
	const emailRef = useRef(undefined);
	const passwordRef = useRef(undefined);
	const checkboxRef = useRef(undefined);

	const handle = getSubdomain(window.location.href);

	const {
		data: account,
		error: accountError,
		isFetching: isLoadingAccount,
	} = useQuery({
		queryKey: [`account_handle_${handle}`],
		queryFn: async () => {
			const res = await axios.get(`${BASE_URL}/api/validate/handle/${handle}.json`);

			if (res.data.error) {
				throw new Error(res.data.error);
			}

			return res.data.account;
		},
		retry: 1,
		enabled: process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test",
	});

	useEffect(() => {
		if (account) {
			document.title = `${account.company} - Logga in - Sellfinity`;
		}
	}, [account]);

	useEffect(() => {
		clearLogin();

		const searchParams = new URLSearchParams(document.location.search);
		if (searchParams.get("refresh_token")) {
			if (history) history.replace(`/admin/?refresh_token=${searchParams.get("refresh_token")}`);
			else window.location.assign(`/admin/?refresh_token=${searchParams.get("refresh_token")}`);
			return;
		}

		if (history) {
			history.replace("/admin/authentication/login");
		} else {
			window.history.replaceState({}, document.title, "/admin/authentication/login");
		}
	}, [history]);

	const searchParams = new URLSearchParams(document.location.search);
	if (searchParams.get("refresh_token")) {
		if (history) history.replace(`/admin/?refresh_token=${searchParams.get("refresh_token")}`);
		else window.location.assign(`/admin/?refresh_token=${searchParams.get("refresh_token")}`);
		return <div />;
	}

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);
		const username = emailRef.current?.value;
		const password = passwordRef.current?.value;

		axios
			.post(`${BASE_URL}/api/login_check`, {
				username,
				password,
				acc_id: params.get("acc_id") || params.get("accountId") || null,
			})
			.then(async (result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					setError(result.data.error);
					return;
				}

				if ((typeof result.data === "string" && result.data?.startsWith("<!DOCTYPE html>")) || (!result.data.token && !result.data.temp_token)) {
					console.debug("result:", result);
					setError("Something went wrong. Please try again later, or contact support.");
					return;
				}

				if (result.data.temp_token) {
					setTempToken(result.data.temp_token);
				}

				if (result.data.needs_to_setup_otp) {
					history.push(`/admin/authentication/login/otp/setup`);
					return;
				}
				if (result.data.mfa_required) {
					history.push(`/admin/authentication/login/otp/verify`);
					return;
				}

				handleJwtLogin({
					token: result.data.token,
					refreshToken: result.data.refresh_token,
					rememberMeChecked,
				});

				return result.data;
			})
			.catch((error) => {
				setError(error?.response?.data?.message || error?.message);
				console.error("error:", error);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Wrapper>
				<LeftWrapper>
					<h1>Logga in</h1>
				</LeftWrapper>

				<RightWrapper>
					<FormWrapper style={{ maxWidth: "unset" }}>
						<a
							className="logo_link"
							style={{ display: "flex", marginBottom: "50px", marginTop: "-100px", position: " unset" }}
							href="https://www.sellfinity.com"
						>
							<img
								alt=""
								className="logo"
								src={
									window.location.href.includes("acordo.se") || window.location.href.includes("nollettnollnoll.se")
										? "/assets/acordo/acordo-logo.png"
										: "/assets/sellfinity/sellfinity-highres-login-c.png"
								}
							/>
						</a>
						<h1 className="form_title">Välkommen tillbaka!</h1>
						<div style={{ display: "flex", alignItems: "center", gap: "0.35rem" }}>
							Konto:{" "}
							{account ? (
								<b>{account?.company}</b>
							) : accountError ? (
								<div className="invalid form-error" style={{ padding: 0 }}>
									{accountError}
								</div>
							) : null}
							{isLoadingAccount && <Spinner size={"small"} />}
						</div>
						<Form id="loginform" aria-label="form" onSubmit={onSubmit}>
							<div>
								<p className="label">E-post</p>
								<input type="text" name="email" className="form_input" ref={emailRef} placeholder="E-post.." />
							</div>
							<div>
								<p className="label">Lösenord</p>
								<input className="form_input" type="password" name="password" ref={passwordRef} placeholder="Lösenord.." />
							</div>

							<div style={{ display: "flex", gap: "0.3125rem", color: "#ffffff", alignItems: "center" }}>
								<input
									type="checkbox"
									id="remember_me"
									name="_remember_me"
									checked={rememberMeChecked}
									ref={checkboxRef}
									onChange={(v) => {
										setRememberMeChecked(v.target.checked);
									}}
								/>
								<span>Kom ihåg mig</span>
							</div>
							<button className="form_button light fullWidth" type="submit" id="_submit" name="_submit" loading={String(loading)}>
								<div className="spinner">
									<div />
									<div />
									<div />
									<div />
								</div>

								<span>Logga in</span>
							</button>

							<a className="helptext" href={`${BASE_URL}/admin/authentication/resetting/request`}>
								Glömt lösenord
							</a>
							{error && <div className="invalid form-error">{error}</div>}
						</Form>
					</FormWrapper>
				</RightWrapper>
			</Wrapper>

			<FindAccountModal
				open={!isLoadingAccount && accountError}
				onClose={() => {
					// setAccountError(null);
					// setAccount(null);
				}}
				// eslint-disable-next-line react-compiler/react-compiler
				email={emailRef.current?.value}
			/>
		</>
	);
};
export default Login;

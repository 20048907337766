import React from "react";
import { Route, Switch } from "react-router-dom";
import { LoginProvider } from "./LoginContext";
import Login from "./Login";
import OTPVerify from "./Views/OTPVerify";
import OTPSetup from "./Views/OTPSetup";

const Routes = (props) => {
	const parentPath = props.match.path;

	return (
		<LoginProvider>
			<Switch>
				<Route exact path={`${parentPath}/otp/setup`} component={OTPSetup} />
				<Route exact path={`${parentPath}/otp/verify`} component={OTPVerify} />
				{/* <Route exact path={`${}/`} component={Test} /> */}

				<Route exact path={parentPath} component={Login} />
				<Route path="*">
					<Route exact path={parentPath} component={Login} />
				</Route>
			</Switch>
		</LoginProvider>
	);
};
export default Routes;
